import { Link, navigate } from "gatsby";
import React, {useState, useEffect} from "react";
import "./Layout.css";
import logo from "./images/logo.png";
import { usePageTree } from "./usePageTree";
import { isLoggedIn } from "./components/auth/auth"

const Navigation = ({tree, root, selectedSite}) => {
    const [collapsed, setCollapsed] = useState(true);
    
    useEffect(() => {
        const pathname = decodeURI(window.location.pathname);

        // lets find a root page to show if any
        if ((pathname === "/" || !pathname.startsWith(`/${selectedSite}`)) && root && tree.isFolder){
            Object.keys(tree).forEach(dir => {
                const sub = tree[dir];
                if(sub && !sub.isFolder) {
                    navigate(sub.path);
                }
            });
        }

        if (root || (tree && tree.path && pathname.includes(tree.path))) {
            setCollapsed(false);
        }
    }, [tree, root, selectedSite]);

    const dirs = Object.keys(tree).filter(dir => dir !== "name" && dir !== "path" && dir !== "isFolder");

    if (root) {
        return dirs.map(dir => <Navigation tree={tree[dir]} key={dir} root={false} />)
    }

    const handleFolderClicked = (e, tree) => {
        e.preventDefault();
        setCollapsed(!collapsed);
        navigate(tree.path);
    }

    const handleItemClicked = (tree) => {
        let watchedVideos = localStorage.getItem("watchedVideos");
        watchedVideos = watchedVideos ? JSON.parse(watchedVideos) : []; 
        watchedVideos.push(tree.path);
        localStorage.setItem("watchedVideos", JSON.stringify(watchedVideos));
    }

    const isWatched = (tree) => {
        let watchedVideos = localStorage.getItem("watchedVideos");
        watchedVideos = watchedVideos ? JSON.parse(watchedVideos) : [];
        return watchedVideos.find(w => w === tree.path) ? true : false;
    }

    return (
        <div className={`navigation-item ${root ? "root" : ""} ${tree.isSection ? "section": ""} ${tree.isChapter ? "chapter": ""}`}>
            {
                tree.name 
                ? tree.isFolder 
                    ? <button className={`navigation-folder ${collapsed ? "closed" : "open"} ${tree.isSection ? "section": ""} ${tree.isChapter ? "chapter": ""}`} onClick={(e) => handleFolderClicked(e, tree)}>
                        <div>{tree.name}</div>
                      </button>
                    : <Link to={tree.path} onClick={() => handleItemClicked(tree)} activeClassName="link-active" className={isWatched(tree) ? "watched": ""}>{tree.name}</Link> 
                : null
            }
            <div className={`navigation-list ${tree.isChapter ? "open" : collapsed ? "closed" : "open"}`}>
                {
                    dirs.map(dir => <Navigation tree={tree[dir]} key={dir} root={false} />)
                }
            </div>
        </div>
    );
};

const SiteSelection = ({setSiteTree, selectedSite, setSelectedSite}) => {
    const tree = usePageTree();
    const siteKeys = Object.keys(tree).filter(s => s !== "isFolder");

    useEffect(() => {
        if (siteKeys.length > 0 && !selectedSite){
            let selected = siteKeys.find(key => window.location.pathname.startsWith(`/${key.toLowerCase()}`));
            if(!selected) selected = siteKeys[0];
            setSelectedSite(selected);
            setSiteTree(tree[selected]);
        }
    }, [selectedSite, setSelectedSite, setSiteTree, siteKeys, tree])

    const handleChange = (event) => {
        setSelectedSite(event.target.value);
        setSiteTree(tree[event.target.value]);
    };

    return (
        <div className="site-select">
            <select value={selectedSite || siteKeys[0]} onChange={handleChange}>
                {
                    siteKeys.map(site => {
                        return <option key={site} value={site}>{tree[site].name}</option>    
                    })
                }
            </select>
        </div>
    );
};

const Layout = ({children, location}) => {    
    const [siteTree, setSiteTree] = useState({});
    const [selectedSite, setSelectedSite] = useState();

    useEffect(() => {
        if (!isLoggedIn() && location.pathname !== "/login") {
            navigate("/login");
        }
    });

    if(location.pathname === "/login"){
        return children;
    }

    return (
        <div className="main-layout">
            <Link to="/" className="logo">
                <img src={logo} alt="logo"></img>
            </Link>
            <aside>
                <nav>
                    <Navigation tree={siteTree} selectedSite={selectedSite} root />
                </nav>
            </aside>
            <main>
                <SiteSelection setSiteTree={setSiteTree} selectedSite={selectedSite} setSelectedSite={setSelectedSite} />
                {children}
            </main>
            <footer>
                <div className="cookie-disclaimer">Vi benytter ikke cookies <span role="img" aria-label="smiley">&#128578;</span></div>
                2021 © Copyrights Emento A/S
            </footer>
        </div>
    );
}

export default Layout;