import { useMemo } from "react";
import { graphql, useStaticQuery } from "gatsby";

export const usePageTree = () => {
    const data = useStaticQuery(graphql`
        query NavQuery {
            allGoogleSheet {
                nodes {
                    id
                    Ark1 {
                        id
                        titel
                        kapitel
                        sektion
                        side
                        url
                    }
                }
            }
        }
    `);
    
    const getPathFromName = (name) => {
        const path = name.toLowerCase().split(" ").join("-");
        return `/${path}`;
    };

    const tree = useMemo(() => {
        const rows = data.allGoogleSheet.nodes[0].Ark1;
    
        const tree = {
            isFolder: true
        };

        rows.forEach(row => {
            const side = tree[row.side] || {
                isFolder: true,
                isPage: true,
                path: getPathFromName(row.side),
                name: row.side
            };

            const sektion = side[row.sektion] || {
                isFolder: true,
                isSection: true,
                path: getPathFromName(row.side) + getPathFromName(row.sektion),
                name: row.sektion,
            };

            side[row.sektion] = sektion;
            
            const kapitel = sektion[row.kapitel] || {
                isFolder: true,
                isChapter: true,
                path: getPathFromName(row.side) + getPathFromName(row.sektion) + getPathFromName(row.kapitel),
                name: row.kapitel,
            };

            sektion[row.kapitel] = kapitel;

            const titel = {
                path: getPathFromName(row.side) + getPathFromName(row.sektion) + getPathFromName(row.kapitel) + getPathFromName(row.titel),
                name: row.titel,
            };

            kapitel[row.titel] = titel;

            tree[row.side] = side;
        });

        return tree;
    }, [data.allGoogleSheet.nodes]);

    return tree;
}