export const isBrowser = () => typeof window !== "undefined";

export const getUser = () =>
    isBrowser() && window.localStorage.getItem("ementoAcademyUser")
        ? JSON.parse(window.localStorage.getItem("ementoAcademyUser"))
        : {};

const setUser = (user) => window.localStorage.setItem("ementoAcademyUser", JSON.stringify(user));

export const handleLogin = ({ password }) => {
    if (password === process.env.GATSBY_AUTH_PASSWORD) {
        return setUser({
            username: "ementoAcademyUser",
            name: "Emento Academy",
        });
    }

    return false;
};

export const isLoggedIn = () => {
    const user = getUser();

    return !!user.username;
};

export const logout = (callback) => {
    setUser({});
    callback();
};
